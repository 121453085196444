import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function NavBar({metaMaskAccount, blockchainNetwork}) {
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="image" component="div" sx={{flexGrow: 1}}>
                        <img className={"logo"} src={"./BlockchainCommoditiesLogo.svg"} alt={'Blockchain Commodities'} />
                    </Typography>
                    <Typography>
                        Connected Account: {metaMaskAccount}
                        <br/>
                        Network: {blockchainNetwork?.name}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
