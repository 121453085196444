import {ContentCopy, Download} from "@mui/icons-material";
import {CardActions, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";

export default function ShowContractDetails({
                                                provider,
                                                contractDetails,
                                                smartContractContentJSON,
                                                chainId,
                                                blockChainNetwork
                                            }) {

    const [content, setContent] = useState([]);


    useEffect(() => {

        setContractContent();

    })

    const setContractContent = async () => {
        if (content.length === 0) {
            const currentContent = [{
                key: 'network', value: blockChainNetwork?.name
            }, {
                key: 'chainId', value: chainId
            }, {
                key: 'contractName', value: smartContractContentJSON.contractName
            }, {
                key: 'contractAddress', value: contractDetails.contractAddress,
            }, {
                key: 'transactionId', value: contractDetails.transactionHash,
            }, {
                key: 'blockNumber', value: contractDetails.blockNumber,
            }, {
                key: 'blockHash', value: contractDetails.blockHash,
            }, {
                key: 'account', value: contractDetails.from,
            }, {
                key: 'gasUsed', value: contractDetails.gasUsed,
            }, {
                key: 'cumulativeGasUsed', value: contractDetails.cumulativeGasUsed,
            }, {
                key: 'effectiveGasPrice', value: contractDetails.effectiveGasPrice,
            }]
            setContent(currentContent);
        }
    }

    const downloadContractDetails = async () => {
        try {
            const element = document.createElement("a");
            const file = await new Blob([convertContentToText()], {
                type: "text/plain"
            });
            element.href = URL.createObjectURL(file);
            element.download = `contract-details-${smartContractContentJSON.contractName}-${blockChainNetwork.name}-${chainId}.txt`;
            document.body.appendChild(element);
            element.click();
            element.remove();
        } catch (e) {
            console.error("Error while downloading contract details");
        }
    }

    const copyContractDetailsToClipBoard = async () => {
        await navigator.clipboard.writeText(convertContentToText());
    }

    const convertContentToText = () => {
        let contractDetails = "";
        contractDetails = content.map((contentDetails) => (
            contractDetails + "\n" + contentDetails.key + " : " + contentDetails.value?.toString()
        ));
        return contractDetails;
    }

    const bull = (
        <Box
            component="span"
            sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}
        >
        </Box>
    );

    return (
        <Card>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    DO{bull}NOT{bull}FORGET{bull}TO{bull}COPY{bull}OR{bull}DOWNLOAD
                </Typography>
                <Typography variant="h5" component="div">
                    Contract Details
                </Typography>
                <TableContainer>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    Contract Fields
                                </TableCell>
                                <TableCell align="left"> Values</TableCell>
                            </TableRow>
                            {content && content.map((contentDetail, index) => (
                                <TableRow key={index}
                                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {contentDetail.key}
                                    </TableCell>
                                    <TableCell align="left"> {contentDetail.value?.toString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button variant="contained" startIcon={<ContentCopy/>} onClick={copyContractDetailsToClipBoard}>
                    Copy Deployment Details
                </Button>
                <Button variant="contained" startIcon={<Download/>} onClick={downloadContractDetails}>
                    Download Deployment Details
                </Button>
            </CardActions>
        </Card>
    );
}