import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


/**
 * Component function to display confirmation dialog
 * @param props
 * @returns
 */
export default function WalletsDialog({
                                          handleDisagree,
                                          handleAgree,
                                      }) {


    return (
        <Dialog

            open={true}
            onClose={() => handleDisagree()}
            aria-labelledby="wallet-dialog-title"
        >
            <DialogTitle id="wallets-dialog-title">Select your wallet</DialogTitle>
            <DialogContent>
                <div onClick={() => handleAgree()} style={{display: 'flex', justifyContent: 'center'}}>
                    <img className={"wallet-icon"} src={require("../../assets/MetaMask.png")} alt={'metamask'} />
                </div>
            </DialogContent>

        </Dialog>
    );
}
