import './App.css';
import {useEffect, useState} from "react";
import {useMetaMask} from "metamask-react";
import Container from "@mui/material/Container";
import Loader from "./components/common/Loader";
import Toast from "./components/common/Toast";
import NavBar from "./components/Navbar";
import UploadContract from "./components/contract-utils/UploadContract";
import {createTheme, ThemeProvider} from "@mui/material";
import ConfirmationDialog from "./components/common/ConfirmationDialog";
import chainData from './configs/chains.json';
import WalletsDialog from "./components/common/WalletsDialog";


function App() {

    const {status, connect, account, chainId, ethereum} = useMetaMask();
    const [loader, showLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [toast, showToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("info");
    const [currentStatus, setCurrentStatus] = useState("");
    const [currentNetwork, setCurrentNetwork] = useState("");
    const [connectMetamaskDialog, showConnectMetamaskDialog] = useState(false);
    const [installMetamaskDialog, showInstallMetamaskDialog] = useState(false);
    const [connectWalletsDialog, showWalletsDialog] = useState(false);
    const [blockchainNetwork, setBlockchainNetwork] = useState("");

    useEffect(() => {


        if (status !== currentStatus) {
            setCurrentStatus(status);
            if (status === "initializing") {
                setLoaderMessage("Synchronisation with MetaMask...")
                showLoader(true);
            } else if (status === "unavailable") {
                showLoader(false);
                showInstallMetamaskDialog(true);
            } else if (status === "notConnected") {
                showLoader(false);
                showConnectMetamaskDialog(true);
            } else if (status === "connecting") {
                setLoaderMessage("Connecting with Wallet...")
                showLoader(true);
            } else if (status === "connected") {
                const toastMessage = `Connected account on Wallet is ${account} on chain ID ${chainId}`
                setToastSeverity('success');
                setToastMessage(toastMessage);
                showToast(true);
                showLoader(false);
            }
        }
        if (chainId !== currentNetwork) {
            setCurrentNetwork(chainId);
            getNetworkName(chainId);
        }
    }, [status, currentStatus, account, chainId, currentNetwork]);

    /**
     * Handler for closing Toast
     * @param event
     * @param reason
     * @returns
     */
    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        showToast(false);
    };

    const closeMetamaskDialog = () => {
        showConnectMetamaskDialog(false);
        showInstallMetamaskDialog(false);
        showWalletsDialog(false);
    }

    const handleWalletConnectAgree = () => {
        showConnectMetamaskDialog(false);
        showWalletsDialog(true);
    }

    const handleMetamaskConnectAgree = () => {
        showConnectMetamaskDialog(false);
        showWalletsDialog(false);
        connect().then(r => {
            console.log(r);
        });
    }

    const handleMetamaskInstallAgree = () => {
        window.open("https://metamask.io/download/");
        showInstallMetamaskDialog(false);
    }

    const getNetworkName = (chainIdHex) => {
        const chainId = parseInt(chainIdHex, 16)
        const result = chainData.filter(chainObject => chainObject.chainId === chainId);
        if (result.length === 0) {
            setBlockchainNetwork(chainIdHex);
        } else {
            setBlockchainNetwork(result[0])
        }
    }

    /**
     * Custom Theme
     * @type {Theme}
     */
    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#000000',
            },
            secondary: {
                main: '#081437',
            }
        },
    });


    return (
        <ThemeProvider theme={customTheme}>
            <NavBar
                metaMaskAccount={account}
                blockchainNetwork={blockchainNetwork}
            />
            <UploadContract
                metaMaskChainId={chainId}
                blockchainNetwork={blockchainNetwork}
                ethereum={ethereum}
                showLoader={showLoader}
                setLoaderMessage={setLoaderMessage}
            />
            <Container component="main">
                {loader &&
                    <Loader
                        message={loaderMessage}
                    />
                }
                {toast && (
                    <Toast
                        message={toastMessage}
                        severity={toastSeverity}
                        handleToastClose={handleToastClose}
                    />
                )}
                {connectMetamaskDialog && (
                    <ConfirmationDialog
                        agreeText="Connect"
                        notAgreeText="Cancel"
                        title="Wallet not connected"
                        message="Your wallet is not connected! Please connect your wallet"
                        handleDisagree={closeMetamaskDialog}
                        handleAgree={handleWalletConnectAgree}
                    />
                )}
                {installMetamaskDialog && (
                    <ConfirmationDialog
                        agreeText="Install"
                        notAgreeText="Cancel"
                        title="Metamask not available"
                        message="This application could not find Metamask, please install Metamask."
                        handleDisagree={closeMetamaskDialog}
                        handleAgree={handleMetamaskInstallAgree}
                    />
                )}
                {connectWalletsDialog && (
                    <WalletsDialog
                        handleDisagree={closeMetamaskDialog}
                        handleAgree={handleMetamaskConnectAgree}
                    />
                )}
            </Container>
        </ThemeProvider>

    );
}

export default App;
