import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useRef, useState} from "react";
import SmartContractInputs from "./SmartContractInputs";
import {ethers} from "ethers";
import ShowContractDetails from "./ShowContractDetails";
import ConfirmationDialog from "../common/ConfirmationDialog";
import LibrariesInputs from "./LibrariesInputs";


export default function UploadContract({
                                           ethereum,
                                           showLoader,
                                           setLoaderMessage,
                                            metaMaskChainId,
                                            blockchainNetwork
                                       }) {

    const [compiledSmartContract, setCompiledSmartContract] = useState("");
    const [errors, setErrors] = useState({});
    const [compiledSmartContractContentJSON, setCompiledSmartContractContentJSON] = useState("");
    const [smartContractInputValues, setSmartContractInputValue] = useState([]);
    const [contractDetails, setContractDetails] = useState();
    const [provider, setProvider] = useState();
    const [dialog, showDialog] = useState(false);
    const [dialogBtnText, setDialogBtnText] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [librariesSpace, setLibrariesSpace] = useState(null);
    const [librariesInputValues, setLibrariesInputValue] = useState([]);
    const fileRef = useRef(null);


    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isErrorPresent()) {
            setLoaderMessage("Uploading Smart Contract! Waiting for metamask approval...");
            showLoader(true);
            setContractDetails(undefined);
            uploadContract().then(() => {
                console.log("Contract Uploaded Successfully: ");
            }).catch(error => {
                console.error("Error occurred while uploading contract: ", error);
                showLoader(false);
                setDialogBtnText("OK");
                setDialogTitle(`Error ${error.code}`);
                setDialogMessage(error.message.includes('provider')
                    ? "Metamask connection not found. Please install/connect metamask."
                    : error.message);
                showDialog(true);
            })
        } else {
            console.warn("Errors present in the form");
        }
    }


    const defaultConfirmationDialogClose = () => {
        showDialog(false);
    }

    /**
     * Method to handle changes on text fields in form
     * It also enables submit button once all fields are filled in and validated
     * @param event
     */
    const handleFileChange = (event) => {

        let isError = false;

        if (event && event.target && event.target.files[0]) {
            const uploadedFile = event.target.files[0];
            if (uploadedFile.type !== 'application/json') {
                isError = true;
                setErrors({...errors, compiledSmartContract: `Uploaded file is not application/json`});
            } else {
                setErrors({...errors, compiledSmartContract: undefined});
                const fileReader = new FileReader()
                fileReader.onload = async (progressEvent) => {
                    try {
                        const uploadedFileContentJSON = JSON.parse(progressEvent.target.result.toString());
                        setCompiledSmartContractContentJSON(JSON.parse(progressEvent.target.result.toString()));
                        if (uploadedFileContentJSON.bytecode == null || Object.keys(uploadedFileContentJSON.bytecode).length === 0) {
                            isError = true;
                            setErrors({
                                ...errors,
                                compiledSmartContract: `Invalid Smart Contract JSON file: Bytecode not found`
                            });
                        } else {
                            getLibraries(uploadedFileContentJSON.bytecode)
                        }
                    } catch (error) {
                        isError = true;
                        console.error(error);
                        setErrors({...errors, compiledSmartContract: `Invalid Smart Contract JSON file: ${error}`});
                    }
                }
                fileReader.readAsText(uploadedFile);

            }
            if (!isError) {
                setCompiledSmartContract(event.target.value);
            } else {
                setCompiledSmartContract("");
                setSmartContractInputValue([]);
                setLibrariesSpace(null);
                setLibrariesInputValue([]);
            }
        }


    };

    /**
     * if all text fields have some values and there are no errors
     * then enable the submit button
     */
    const isErrorPresent = () => {

        let anyError = false;

        if (!compiledSmartContract || compiledSmartContract === "") {
            setErrors(errors => ({...errors, compiledSmartContract: `Smart contract file is required`}));
            anyError = true;
        } else {
            setErrors(errors => ({...errors, compiledSmartContract: undefined}));
        }
        if (compiledSmartContractContentJSON) {
            compiledSmartContractContentJSON?.abi?.filter(arr => arr.type === 'constructor')[0]?.inputs?.forEach((input, index) => {
                if (smartContractInputValues[index] === undefined || smartContractInputValues[index] === '') {
                    setErrors(errors => ({...errors, [input.name]: `${input.name} is required`}));
                    anyError = true;
                } else {
                    setErrors(errors => ({...errors, [input.name]: undefined}));
                }
            });
        }
        if (librariesSpace !== null) {
            librariesSpace.forEach((input, index) => {
                if (librariesInputValues[index] === undefined || librariesInputValues[index] === '') {
                    setErrors(errors => ({...errors, [input]: `Library address of ${input} is required`}));
                    anyError = true;
                } else {
                    setErrors(errors => ({...errors, [input]: undefined}));
                }
            })
        }
        console.log("anyError: ", anyError);
        return anyError;
    };

    const getLibraries = (bytecode) => {
        const regex = /_{2}[A-Za-z\d]*__{2,}/g;
        const result = bytecode.match(regex);
        if (result !== null) {
            setLibrariesSpace(result.map(librariesName => librariesName.replaceAll('_', '')).filter((v, i, a) => a.indexOf(v) === i));
        }
    }

    const linkLibrary = (bytecode) => {
        let currentByteCode = bytecode;
        librariesSpace.forEach((libName, index) => {
            const libraryAddress = librariesInputValues[index].startsWith('0x') ? librariesInputValues[index] : `0x${librariesInputValues[index]}`;
            console.log("libraryAddress: ", libraryAddress);
            let symbol = "__" + libName + "_".repeat(40 - libName.length - 2);
            currentByteCode = currentByteCode.split(symbol).join(libraryAddress.toLowerCase().substring(2))
        });
        return currentByteCode;
    }

    const clearFileInput = () => {
        const fileInput = document.getElementById('compiledSmartContract');
        fileInput.value = '';
    }

    const uploadContract = async () => {
        console.log("Upload Contract Method called!");
        const ethProvider = new ethers.providers.Web3Provider(ethereum);
        let bytecode = compiledSmartContractContentJSON.bytecode;
        setProvider(ethProvider);
        console.log("Getting metamask signature!");
        const signer = ethProvider.getSigner();
        // check if contract bytecode libraries are available
        if (librariesSpace !== null) {
            // replace all the libraries values
            bytecode = linkLibrary(compiledSmartContractContentJSON.bytecode);
        }
        // Deploy the contract
        const factory = new ethers.ContractFactory(compiledSmartContractContentJSON.abi, bytecode, signer)
        console.log("Factory: ", factory);
        console.log("...smartContractInputValues: ", ...smartContractInputValues)
        factory.deploy(...smartContractInputValues).then(contract => {
            console.log("contract: ", contract);
            setLoaderMessage("Approved from metamask! Uploading smart contract...")
            contract.deployed().then(deployedResult => {
                console.log("deployedResult: ", deployedResult);
                setLoaderMessage("Contract Uploaded Successfully! Fetching contract details...");
                const contractTransaction = deployedResult.deployTransaction.hash;
                ethProvider.once(contractTransaction, (transaction) => {
                    setContractDetails(transaction);
                    showLoader(false);
                    setDialogBtnText("OK");
                    setDialogTitle("Success");
                    setDialogMessage("Smart Contract Uploaded successfully. Please do not forget to copy or download deployed contract details.");
                    showDialog(true);
                    setCompiledSmartContract("");
                    setSmartContractInputValue([]);
                    setLibrariesSpace(null);
                    setLibrariesInputValue([]);
                    clearFileInput();

                });
            }).catch(error => {
                showLoader(false);
                setDialogBtnText("OK");
                setDialogTitle(`Error ${error.code}`);
                setDialogMessage(error.message);
                showDialog(true);
                console.log(error);
            })
        }).catch(error => {
            showLoader(false);
            setDialogBtnText("OK");
            setDialogTitle(`Error ${error.code}`);
            setDialogMessage(error.message);
            showDialog(true);
            console.log(error);
        })
    }


    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <UploadFileRoundedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Upload Smart Contract
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="compiledSmartContract"
                        label="Upload compiled smart contract"
                        name="compiledSmartContract"
                        focused
                        type="file"
                        onChange={handleFileChange}
                        error={Boolean(errors?.compiledSmartContract)}
                        helperText={errors?.compiledSmartContract}
                        ref={fileRef}
                        key={0}
                    />
                    {compiledSmartContract
                        && compiledSmartContractContentJSON
                        && Object.keys(compiledSmartContractContentJSON.abi).length !== 0
                        && Object.keys(compiledSmartContractContentJSON.abi[0].inputs).length !== 0
                        && <SmartContractInputs
                            inputs={compiledSmartContractContentJSON.abi.filter(arr => arr.type === 'constructor')[0]?.inputs}
                            inputsValues={smartContractInputValues}
                            setInputsValues={setSmartContractInputValue}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    }
                    {librariesSpace
                        && librariesSpace.length !== 0
                        && <LibrariesInputs
                            librariesSpace={librariesSpace}
                            inputsValues={librariesInputValues}
                            setInputsValues={setLibrariesInputValue}
                            errors={errors}
                            setErrors={setErrors}/>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Upload
                    </Button>
                </Box>
                {contractDetails && <ShowContractDetails
                    provider={provider}
                    contractDetails={contractDetails}
                    smartContractContentJSON={compiledSmartContractContentJSON}
                    chainId={metaMaskChainId}
                    blockChainNetwork={blockchainNetwork}
                />}
                {dialog && (
                    <ConfirmationDialog
                        agreeText={dialogBtnText}
                        title={dialogTitle}
                        message={dialogMessage}
                        handleDisagree={defaultConfirmationDialogClose}
                        handleAgree={defaultConfirmationDialogClose}
                    />
                )}
            </Box>
        </Container>
    );
}